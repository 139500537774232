<script setup lang="ts">
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import Textarea from 'primevue/textarea'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import { onMounted, type Ref, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import axios from '@/utils/axios'
import { useEnvStore } from '@/stores/envStore'
import type { Supplier } from '@/types/api'
import type { Ingredient } from '@/types/dish'
import { useOrderList } from '@/stores/orderStore.js'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { useFetch } from '@/composables/useFetch.js'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import { useUserStore } from '@/stores/useUserStore'
import jsPDF from 'jspdf'
import OpenSansBold from '@/assets/fonts/open-sans/OpenSans-Bold.ttf'
import OpenSansRegular from '@/assets/fonts/open-sans/OpenSans-Regular.ttf'
import OpenSansLight from '@/assets/fonts/open-sans/OpenSans-Light.ttf'
import AvatarLogo from '@/assets/images/avatar-rounded.webp'
import { useOrder } from '@/composables/useOrders'
import { useConfirm } from 'primevue/useconfirm'
import { adjustUnitsForQuantity } from '@/utils/prices'
import type { TableRow } from '@/types/order'

const orderStore = useOrderList()
const ingredientsToOrder = orderStore.ingredientsToOrder
const { t } = useI18n<{ message: MessageSchema }>()
const user = ref(useUserStore().user)
const toast = useToast()
const router = useRouter()
const { isFetching, fetchData } = useFetch()
const confirm = useConfirm()

type IngredientEditing = Ingredient & {
  type?: 'ingredient' | 'group'
}

const supplierList: Ref<Supplier[]> = ref([])
const ingredientToEdit: Ref<IngredientEditing | null> = ref(null)
const showSidebar = ref(false)
const selectedSupplierUuid: Ref<string | null> = ref(null)
const wantedDate = ref(new Date())
const customMessage = ref('')

const { generatePDF } = useOrder()

function formatDateForAPI(date: Date): string {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

function adjustIngredientsUnits(ingredients: any) {
  const adjustedData = { ...ingredients }

  // Adjust regular ingredients
  if (adjustedData.ingredients) {
    adjustedData.ingredients = adjustedData.ingredients.map(ing => {
      const adjusted = adjustUnitsForQuantity(ing.quantity, ing.unit, 1)
      return {
        ...ing,
        quantity: adjusted.quantity,
        unit: adjusted.unit
      }
    })
  }

  // Adjust groups
  if (adjustedData.groups) {
    adjustedData.groups = adjustedData.groups.map(group => {
      const adjusted = adjustUnitsForQuantity(group.quantity, group.unit, 1)
      return {
        ...group,
        quantity: adjusted.quantity,
        unit: adjusted.unit,
      }
    })
  }

  return adjustedData
}

async function order() {
  if (user.value) {
    const restaurantId = user.value?.restaurants[0].id
    const adjustedIngredients = adjustIngredientsUnits(ingredientsToOrder)

    const { data, error } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/' + restaurantId + '/order',
      'POST',
      {
        ingredients: adjustedIngredients,
        wantedDate: formatDateForAPI(wantedDate.value),
        customMessage: customMessage.value,
        supplierUuid: selectedSupplierUuid.value,
      }
    )

    if (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('orders.orderForm.order.errorDetail'),
        life: 3000,
      })
    } else if (data) {
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: t('orders.orderForm.order.successDetail'),
        life: 3000,
      })

      await orderStore.fetchIngredients(restaurantId)
      router.push({ name: 'orders' })
    }
  }
}

async function fetchSuppliers() {
  if (user.value) {
    const restaurantId = user.value?.restaurants[0].id
    try {
      const response = await axios.get(
        `${useEnvStore().apiUrl}/restaurants/${restaurantId}/suppliers`
      )
      supplierList.value = response.data
    } catch (error) {
      console.error('Erreur lors du chargement des fournisseurs', error)
    }
  }
}

// function addOrUpdateIngredient(ingredient: Ingredient | null) {
//   console.log('addOrUpdateIngredient', ingredient)
//   if (ingredient) {
//     ingredientToEdit.value = ingredient
//   } else {
//     ingredientToEdit.value = { id: -1, category: '', name: '', quantity: 0, unit: '', price: 0 }
//   }
//   showSidebar.value = true
// }

function addIngredient() {
  if (!ingredientToEdit.value || !ingredientToEdit.value.name) {
    console.error('Ingredient data is incomplete.')
    return
  }
  // Adding as regular ingredient
  if (!orderStore.ingredientsToOrder.ingredients) {
    orderStore.ingredientsToOrder.ingredients = []
  }

  // set timestamps for id 
  ingredientToEdit.value.id = -(Date.now())
  console.log("ingredientToEdit.value", ingredientToEdit.value)
  orderStore.ingredientsToOrder.ingredients.push(ingredientToEdit.value)
  showSidebar.value = false
}

function updateIngredient() {
  if (!ingredientToEdit.value) return

  // Check if we're updating a regular ingredient
  const ingredientIndex = orderStore.ingredientsToOrder.ingredients?.findIndex(
    (ing) => ing.id === ingredientToEdit.value?.id
  )

  if (ingredientIndex !== undefined && ingredientIndex !== -1) {
    // Update ingredient in ingredients array
    if (orderStore.ingredientsToOrder.ingredients) {
      orderStore.ingredientsToOrder.ingredients[ingredientIndex] = ingredientToEdit.value
    }
  } else {
    // Check if we're updating a group
    const groupIndex = orderStore.ingredientsToOrder.groups?.findIndex(
      (group) =>
        group.id === ingredientToEdit.value?.id
      // group.name === ingredientToEdit.value?.name &&
      // group.category === ingredientToEdit.value?.category
    )

    if (groupIndex !== undefined && groupIndex !== -1 && orderStore.ingredientsToOrder.groups) {
      // Update group data while preserving its ingredients
      const existingIngredients = orderStore.ingredientsToOrder.groups[groupIndex].ingredients
      orderStore.ingredientsToOrder.groups[groupIndex] = {
        ...ingredientToEdit.value,
        ingredients: existingIngredients
      }
    }
  }

  showSidebar.value = false
}

function deleteIngredients() {
  if (!ingredientToEdit.value) return

  console.log("Deleting Ingredient, ingredientToEdit", ingredientToEdit.value)
  // Check if we're deleting a regular ingredient
  const ingredientIndex = orderStore.ingredientsToOrder.ingredients?.findIndex(
    (ing) => ing.id === ingredientToEdit.value?.id
  )
  console.log("ingredientIndex", ingredientIndex)

  if (ingredientIndex !== undefined && ingredientIndex !== -1) {
    // Delete from ingredients array
    if (orderStore.ingredientsToOrder.ingredients) {
      orderStore.ingredientsToOrder.ingredients.splice(ingredientIndex, 1)
    }
  } else {
    // Check if we're deleting a group
    const groupIndex = orderStore.ingredientsToOrder.groups?.findIndex(
      (group) =>
        group.id === ingredientToEdit.value?.id
      // group.name === ingredientToEdit.value?.name &&
      // group.category === ingredientToEdit.value?.category
    )

    if (groupIndex !== undefined && groupIndex !== -1 && orderStore.ingredientsToOrder.groups) {
      // Delete the group
      orderStore.ingredientsToOrder.groups.splice(groupIndex, 1)
    }
  }

  showSidebar.value = false // Close the sidebar after deleting
}

function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}
function formatOrderForClipboard() {
  const supplier = supplierList.value.find(s => s.uuid === selectedSupplierUuid.value)
  // const date = new Intl.DateTimeFormat(undefined, { dateStyle: 'full' }).format(wantedDate.value)
  // Date format : dd.mm.yyyy
  const date = formatDate(wantedDate.value)

  let text = `${t('orders.orderForm.file.orderOfDate', { date: formatDate(new Date()) })} - ${user.value?.restaurants[0].name}\n\n`
  text += `${t('orders.orderForm.file.provider')}: ${supplier?.name || '-'}\n`
  text += `${t('orders.orderForm.file.deliveryDateWanted')}: ${date}\n\n`
  text += `${t('orders.orderForm.file.ingredientsList')}:\n`
  tableData.value.forEach(ingredient => {
    text += `- ${ingredient.data.quantity} ${ingredient.data.unit} ${ingredient.data.name}\n`
  })
  if (customMessage.value) {
    text += `\n${t('orders.orderForm.file.customMessage')}:\n${customMessage.value}`
  }
  text += `\nRestaurant: \n- ${user.value?.restaurants[0].name}\n- ${user.value?.restaurants[0].phone_number}\n`
  return text
}

async function copyOrderToClipboard() {
  const text = formatOrderForClipboard()
  try {
    await navigator.clipboard.writeText(text)
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('orders.orderForm.copy.success'),
      life: 3000,
    })
  } catch (err) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('orders.orderForm.copy.error'),
      life: 3000,
    })
  }
}

async function handleGeneratePDF() {
  await generatePDF(
    t,
    tableData.value,
    supplierList.value,
    selectedSupplierUuid.value,
    wantedDate.value,
    customMessage.value
  )
}

function handleOrderClick() {
  confirm.require({
    message: t('orders.orderForm.order.confirmMessage'),
    header: t('orders.orderForm.order.confirmHeader'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('orders.orderForm.order.confirmAccept'),
    rejectLabel: t('orders.orderForm.order.confirmReject'),
    accept: () => {
      order()
    },
    reject: () => {
      // Do nothing if rejected
    }
  })
}

onMounted(() => {
  fetchSuppliers()
})


// Modify the computed property to combine both arrays
const tableData = computed<TableRow[]>(() => {
  const rows: TableRow[] = []

  // Add individual ingredients
  if (orderStore.ingredientsToOrder.ingredients) {
    rows.push(...orderStore.ingredientsToOrder.ingredients.map(ing => ({
      type: 'ingredient' as const,
      data: ing
    })))
  }

  // Add groups
  if (orderStore.ingredientsToOrder.groups) {
    rows.push(...orderStore.ingredientsToOrder.groups.map(group => ({
      type: 'group' as const,
      data: {
        ...group,
        // Remove the ingredients array to avoid showing it in the table
        ingredients: undefined
      }
    })))
  }

  return rows
})

function addOrUpdateIngredient(row: TableRow | null) {
  if (!row) {
    // Creating new ingredient
    ingredientToEdit.value = {
      id: -1, // -1 indicates new ingredient in EditIngredient
      category: '',
      name: '',
      quantity: 0,
      unit: '',
      price: 0
    }
  } else if (row.type === 'ingredient') {
    ingredientToEdit.value = row.data
    // if (row.data.id === -1) {
    //   // This is a previously added new ingredient
    //   ingredientToEdit.value = {
    //     ...row.data,
    //     id: -2 // Use -3 to distinguish from new (-1) and group (-2)
    //   }
    // } else {
    //   // This is an existing ingredient
    //   ingredientToEdit.value = row.data
    // }
  } else {
    // This is a group
    ingredientToEdit.value = row.data
  }
  showSidebar.value = true
}
</script>

<template>
  <main class="space-y-4">
    <!-- <div class="p-3 mb-3 bg-primary-100 border border-primary-400 text-primary-700 rounded">
      <p><i class="pi pi-info-circle"></i> {{ t('common.developmentNotice') }}</p>
    </div> -->
    <div class="p-3 mb-3 bg-primary-100 border border-primary-400 text-primary-700 rounded">
      <p><i class="pi pi-info-circle"></i> {{ t('orders.orderForm.informSupplierNotice') }}</p>
    </div>
    <div>
      <h3>{{ t('orders.orderForm.supplier') }}</h3>
      <Dropdown v-model="selectedSupplierUuid" :options="supplierList.filter((s) => s.email)" optionLabel="name"
        option-value="uuid" :placeholder="t('orders.orderForm.supplierPlaceholder')" class="w-full" 
        :emptyMessage="t('orders.orderForm.noSupplierAvailable')" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.wantedDate') }}</h3>
      <Calendar v-model="wantedDate" class="w-full" dateFormat="dd.mm.yy" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.customMessage') }}</h3>
      <Textarea v-model="customMessage" class="w-full" />
    </div>

    <div>
      <h3>{{ t('orders.orderForm.shippingList') }}</h3>
      <DataTable :value="tableData" @row-click="(evt) => addOrUpdateIngredient(evt.data)">
        <Column field="data.quantity" :header="t('orders.orderForm.quantityShort')" />
        <Column field="data.unit" :header="t('orders.orderForm.unit')" />
        <Column field="data.name" :header="t('orders.orderForm.name')" />
      </DataTable>
      <div class="hover:cursor-pointer" @click="addOrUpdateIngredient(null)">
        <p>+ {{ t('profileFavorites.creationForm.addIngredientButton') }}</p>
      </div>
    </div>

    <div class="flex gap-2 items-center">
      <Button :label="`${t('orders.orderForm.order.orderLabel')}`" @click="handleOrderClick" class="flex-1"
        :disabled="!selectedSupplierUuid || !wantedDate || tableData.length === 0" :loading="isFetching" />
      <!-- <Button v-if="useEnvStore().environment === 'production'"
        :label="`${t('orders.orderForm.order.orderLabel')} (${t('common.inDevelopment')})`" @click="null" class="flex-1"
        :loading="isFetching" disabled /> -->
      <Button icon="pi pi-copy" @click="copyOrderToClipboard" :disabled="tableData.length === 0"
        :tooltip="t('orders.orderForm.copy.tooltip')" class="p-button-outlined" />
      <Button icon="pi pi-file-pdf" @click="handleGeneratePDF" :disabled="tableData.length === 0"
        :tooltip="t('orders.orderForm.pdf.tooltip')" class="p-button-outlined p-button-secondary" />
    </div>

    <EditIngredient v-model:ingredient="ingredientToEdit" v-model:visible="showSidebar" @add-ingredient="addIngredient"
      @edit-ingredient="updateIngredient" @delete-ingredient="deleteIngredients" />
  </main>
</template>
