<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import TheAITemperature from '@/components/Menus/Filters/TheAITemperature.vue'
import TheMenuTitle from '@/components/Menus/Filters/TheMenuTitle.vue'
import TheMainIngredient from '@/components/Menus/Filters/TheMainIngredient.vue'
import TheMenuInspirations from '@/components/Menus/Filters/TheMenuInspirations.vue'
import TheMenuDietType from '@/components/Menus/Filters/TheMenuDietType.vue'
import TheMenuSeason from '@/components/Menus/Filters/TheMenuSeason.vue'
import TheMenuProductPreferences from '@/components/Menus/Filters/TheMenuProductPreferences.vue'
import TheAdditionalInstructions from '@/components/Menus/Filters/TheAdditionalInstructions.vue'
import type { GenerationType } from '@/types/api'
import { useFetch } from '@/composables/useFetch'
import { useRouter } from 'vue-router'
import { useDishesStore } from '@/stores/dishesStore'
import LoadingView from '@/views/LoadingView.vue'
import { useEnvStore } from '@/stores/envStore'
import { onMounted, ref, watch, type Ref } from 'vue'
import TheMaxPrice from '@/components/Menus/Filters/TheMaxPrice.vue'
import TheMenuSweetSalt from '@/components/Menus/Filters/TheMenuSweetSalt.vue'
import TheMenuDishType from '@/components/Menus/Filters/TheMenuDishType.vue'
import TheMenuHotCold from '@/components/Menus/Filters/TheMenuHotCold.vue'
import TheMenuRegionInspiration from '@/components/Menus/Filters/TheMenuRegionInspiration.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import { usePlanningStore } from '@/stores/planningStore'
import { useUserStore } from '@/stores/useUserStore'
import TheDietFilters from '@/components/Menus/Filters/TheDietFilters.vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

const router = useRouter()
const toast = useToast()
const user = ref(useUserStore().user)
const dishesStore = ref(useDishesStore())
const planningStore = usePlanningStore()

const { isFetching } = useFetch()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const tabs: Ref<GenerationType[]> = ref(['from_title', 'with_main_product', 'quick'])
const activeTab: Ref<number> = ref(
  tabs.value.indexOf(dishesStore.value.generationParams.generation_type)
)

watch(activeTab, () => {
  dishesStore.value.generationParams.generation_type = tabs.value[activeTab.value]
})

watch(
  () => dishesStore.value.generationParams.generation_type,
  (newType) => {
    activeTab.value = tabs.value.indexOf(newType)
  }
)

const emit = defineEmits<{
  (e: 'startGeneration'): void
  (e: 'generationComplete'): void
}>()

async function getDishes() {
  if (!user.value) return
  if (user.value.restaurants.length == 0) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    })
    return
  }

  if (!useUserStore().validateUserCanGenerate()) {
    return null;
  }

  console.log("Generating dishes with params: ", dishesStore.value.generationParams)


  dishesStore.value.cleanGenerationParams()
  console.log("cleaned params: ", dishesStore.value.generationParams)

  if (
    dishesStore.value.generationParams.generation_type == 'from_title' &&
    dishesStore.value.generationParams.custom_title == ''
  ) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('creationFilters.error.theFieldIsRequired', { field: t('creationFilters.menuTitle') }),
      life: 3000,
    })
    return
  } else if (
    dishesStore.value.generationParams.generation_type == 'with_main_product' &&
    dishesStore.value.generationParams.main_product == ''
  ) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('creationFilters.error.theFieldIsRequired', { field: t('creationFilters.mainProduct') }),
      life: 3000,
    })
    return
  } else if (
    dishesStore.value.generationParams.generation_type == 'quick' &&
    dishesStore.value.generationParams.dish_type == ''
  ) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('creationFilters.error.theFieldIsRequired', { field: t('creationFilters.dishType.label') }),
      life: 3000,
    })
    return
  }

  dishesStore.value.lastGenerationParams = JSON.parse(JSON.stringify(dishesStore.value.generationParams))
  if (planningStore.choosingDishToPlan) {
    emit('startGeneration')
    await dishesStore.value.generateDishes(dishesStore.value.lastGenerationParams)
    emit('generationComplete')

  } else {
    router.push({ path: '/menu/results' })
    dishesStore.value.generateDishes(dishesStore.value.lastGenerationParams)
  }
}

onMounted(() => {
  const queryType = router.currentRoute.value.query.type;

  // Check if queryType is a string and is one of the allowed generation types
  if (typeof queryType === 'string' && tabs.value.includes(queryType as GenerationType)) {
    dishesStore.value.generationParams.generation_type = queryType as GenerationType;
    activeTab.value = tabs.value.indexOf(queryType as GenerationType);
  }
  // console.log('Generation params : ', dishesStore.value.generationParams)
})
</script>

<template>
  <main>
    <div v-show="!dishesStore.isGenerating">
      <TabView :pt="{ inkbar: { class: 'hidden' } }" :pt-options="{ mergeProps: true }" v-model:activeIndex="activeTab">
        <TabPanel :pt="{
          header: { class: '!w-1/2' },
          headerAction: { class: '!justify-center' },
          content: { class: '!px-0' },
        }" :pt-options="{ mergeProps: true }" :header="t('creationFilters.tabs.fromTitle')">
          <div>
            <Button :label="t('creationFilters.resetForm')" @click="dishesStore.resetGenerationParams()" text
              class="!w-full" />
            <TheMenuTitle v-if="dishesStore.generationParams.generation_type === 'from_title'" />

          </div>
        </TabPanel>
        <TabPanel :header="t('creationFilters.tabs.mainProduct')" :pt="{
          header: { class: '!w-1/2' },
          headerAction: { class: '!justify-center' },
          content: { class: '!px-0' },
        }">
          <div>
            <Button :label="t('creationFilters.resetForm')" @click="dishesStore.resetGenerationParams()" text
              class="!w-full" />
            <TheMainIngredient />
          </div>
        </TabPanel>
        <TabPanel :header="t('creationFilters.tabs.quick')" :pt="{
          header: { class: '!w-1/2' },
          headerAction: { class: '!justify-center' },
          content: { class: '!px-0' },
        }">
          <div>
            <Button :label="t('creationFilters.resetForm')" @click="dishesStore.resetGenerationParams()" text
              class="!w-full" />
            <TheMenuDishType />
          </div>
        </TabPanel>
      </TabView>
      <Accordion :activeIndex="0" multiple class="mb-6">
        <AccordionTab :header="t('creationFilters.categories.specs')">
          <TheMenuHotCold />
          <TheMenuSweetSalt />
          <TheMaxPrice />
          <TheMenuInspirations />
          <TheMenuRegionInspiration />
          <TheMenuSeason />
          <!-- <TheMenuProductPreferences /> -->
          <TheAdditionalInstructions />
        </AccordionTab>
        <AccordionTab :header="t('creationFilters.categories.diet')">
          <TheDietFilters />
        </AccordionTab>
      </Accordion>
      <!-- <TheAITemperature v-if="useEnvStore().environment == 'local' || useEnvStore().environment == 'development'"
        @update-ai-temperature="useDishesStore().generationParams.ai_temperature = $event" /> -->

      <Button :label="t('creationFilters.button')" class="w-full" size="large" :disabled="isFetching"
        @click="getDishes()" />
    </div>

    <!-- <div v-if="isFetching && isGenerating">
      <LoadingView :message="t('creationFilters.loadingLabel')" :generation="true" />
    </div> -->
  </main>
</template>
