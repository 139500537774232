<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref, watchEffect } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import Slider from 'primevue/slider'
import Checkbox from 'primevue/checkbox'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = ref(useDishesStore())

const nutrients = ref([
  { key: 'calories', min: 0, max: 2000, enabled: false },
  { key: 'protein', min: 0, max: 100, enabled: false },
  { key: 'carbohydrates', min: 0, max: 200, enabled: false },
  { key: 'fat', min: 0, max: 100, enabled: false },
  { key: 'saturatedFat', min: 0, max: 50, enabled: false },
  { key: 'sugars', min: 0, max: 100, enabled: false },
  { key: 'salt', min: 0, max: 10, enabled: false },
  { key: 'dietaryFiber', min: 0, max: 50, enabled: false }
])

const toggleNutrient = (nutrient: { key: string; min: number; max: number; enabled: boolean }) => {
  if (!nutrient.enabled) {
    delete dishesStore.value.generationParams.nutrients[nutrient.key]
  } else {
    dishesStore.value.generationParams.nutrients[nutrient.key] = [Math.round(nutrient.max * 0.1), Math.round(nutrient.max * 0.4)]
  }
}

const getNutrientUnit = (key: string): string => {
  return key === 'calories' ? 'kcal' : 'g'
}

const handleRangeChange = (nutrientKey, newValue) => {
  console.log("Handle RANGE CHANGE ")
  // round to integer (no digit)
  let [min, max] = newValue.map(value => Math.round(Number(value)));

  if (min > max) {
    [min, max] = [max, min];
  }
  // Update store with rounded values
  dishesStore.value.generationParams.nutrients[nutrientKey] = [min, max];
};

</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.nutrients') }}</template>
    <template #content>
      <div>
        <p class="text-sm italic mb-4 mt-0">{{ t('creationFilters.nutrientsDescription') }}</p>
      </div>
      <div class="flex flex-col gap-4 w-full">
        <div v-for="nutrient in nutrients" :key="nutrient.key" class="flex flex-col gap-2">
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              <Checkbox v-model="nutrient.enabled" @change="toggleNutrient(nutrient)" :binary="true"
                :inputId="nutrient.key" />
              <label :for="nutrient.key">{{ t(`dish.nutritional.${nutrient.key}`) }}</label>
            </div>
            <span v-if="nutrient.enabled">
              {{ dishesStore.generationParams.nutrients[nutrient.key][0] }}-{{
              dishesStore.generationParams.nutrients[nutrient.key][1] }}{{ getNutrientUnit(nutrient.key) }} </span>
          </div>
          <div class="pl-2">
            <Slider v-if="nutrient.enabled" v-model="dishesStore.generationParams.nutrients[nutrient.key]"
              :min="nutrient.min" :max="nutrient.max" range :step="1" class="w-full"
              @change="handleRangeChange(nutrient.key, $event)" />
          </div>
        </div>
      </div>
    </template>
  </CreationFilter>
</template>
