<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import Chip from 'primevue/chip'
import InputText from 'primevue/inputtext'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = ref(useDishesStore())
const inputValue = ref('')

const addIngredient = (ingredient: string) => {
  const trimmedIngredient = ingredient.trim()
  if (trimmedIngredient && !dishesStore.value.generationParams.excluded_ingredients.includes(trimmedIngredient)) {
    dishesStore.value.generationParams.excluded_ingredients.push(trimmedIngredient)
  }
  inputValue.value = ''
}

const handleInput = (event: any) => {
  const value = event.target.value
  if (value.endsWith(';') || value.endsWith(',')) {
    addIngredient(value.slice(0, -1))
  }
}

const handleEnter = () => {
  if (inputValue.value) {
    addIngredient(inputValue.value)
  }
}

const removeIngredient = (ingredient: string) => {
  dishesStore.value.generationParams.excluded_ingredients =
    dishesStore.value.generationParams.excluded_ingredients.filter(i => i !== ingredient)
}
</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.excludedIngredients') }}</template>
    <template #content>
      <div>
        <p class="text-sm italic mb-4 mt-0">{{ t('creationFilters.excludedIngredientsDescription') }}</p>
        <div class="flex flex-col gap-4">
          <InputText v-model="inputValue" type="text" :placeholder="t('creationFilters.enterIngredientToExclude')"
            @input="handleInput" @keyup.enter="handleEnter" @blur="handleEnter" class="w-full" />
          <div class="flex flex-row flex-wrap gap-2">
            <Chip v-for="ingredient in dishesStore.generationParams.excluded_ingredients" :key="ingredient"
              :label="ingredient" removable :pt="{
                root: {
                  class: 'inline-flex items-center rounded-2xl gap-2 px-3 bg-red-100 text-red-900' }
              }" @remove="removeIngredient(ingredient)">
              <template #default>
                <div class="leading-4 my-1.5 mx-0 text-xs">{{ ingredient }}</div>
              </template>
            </Chip>
          </div>
        </div>
      </div>
    </template>
  </CreationFilter>
</template>
