<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref, computed } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import MultiSelect from 'primevue/multiselect'
import Chip from 'primevue/chip'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = ref(useDishesStore())

const allergens = computed(() => [
  { name: t('creationFilters.allergensList.milk'), value: 'milk' },
  { name: t('creationFilters.allergensList.eggs'), value: 'eggs' },
  { name: t('creationFilters.allergensList.fish'), value: 'fish' },
  { name: t('creationFilters.allergensList.crustaceans'), value: 'crustaceans' },
  { name: t('creationFilters.allergensList.molluscs'), value: 'molluscs' },
  { name: t('creationFilters.allergensList.treeNuts'), value: 'treeNuts' },
  { name: t('creationFilters.allergensList.peanuts'), value: 'peanuts' },
  { name: t('creationFilters.allergensList.gluten'), value: 'gluten' },
  { name: t('creationFilters.allergensList.soybeans'), value: 'soybeans' },
  { name: t('creationFilters.allergensList.celery'), value: 'celery' },
  { name: t('creationFilters.allergensList.mustard'), value: 'mustard' },
  { name: t('creationFilters.allergensList.sesame'), value: 'sesame' },
  { name: t('creationFilters.allergensList.sulfites'), value: 'sulfites' },
  { name: t('creationFilters.allergensList.lupin'), value: 'lupin' }
])

const selectedAllergens = computed({
  get: () => dishesStore.value.generationParams.allergens,
  set: (value) => {
    dishesStore.value.generationParams.allergens = value
  }
})

const selectedAllergensDisplay = computed(() => {
  return selectedAllergens.value.map(allergenValue => {
    const allergen = allergens.value.find(a => a.value === allergenValue)
    return allergen ? allergen : { name: allergenValue, value: allergenValue }
  })
})

const removeAllergen = (allergenToRemove: string) => {
  selectedAllergens.value = selectedAllergens.value.filter(allergen => allergen !== allergenToRemove)
}
</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.allergens') }}</template>
    <template #content>
      <div>
        <p class="text-sm italic mb-4 mt-0">{{ t('creationFilters.allergensDescription') }}</p>
      </div>
      <MultiSelect v-model="selectedAllergens" :options="allergens" optionLabel="name" optionValue="value"
      :placeholder="t('creationFilters.allergensDescription')" class="w-full" :maxSelectedLabels="0"
      :selectedItemsLabel="t('creationFilters.allergensSelected', { count: selectedAllergens.length })" />
      <div v-if="selectedAllergens.length > 0" class="flex flex-row flex-wrap gap-2 mt-4">
        <Chip v-for="allergen in selectedAllergensDisplay" :key="allergen.value" removable :pt="{
          root: {
            class: 'inline-flex items-center rounded-2xl gap-2 px-3 bg-red-100 text-red-900'
          }
        }" @remove="removeAllergen(allergen.value)">
          <template #default>
            <div class="leading-4 my-1.5 mx-0 text-xs">{{ allergen.name }}</div>
          </template>
        </Chip>
      </div>
    </template>
  </CreationFilter>
</template>
