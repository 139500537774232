<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import SelectWithoutIcon from '@/components/BaseComponents/SelectWithoutIcon.vue'
import { ref } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import TheMenuDietType from '@/components/Menus/Filters/TheMenuDietType.vue'
import TheMenuNutrients from '@/components/Menus/Filters/TheMenuNutrients.vue'
import TheMenuAllergens from '@/components/Menus/Filters/TheMenuAllergens.vue'
import TheIngredientExclusions from '@/components/Menus/Filters/TheIngredientExclusions.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const dishesStore = ref(useDishesStore());


</script>

<template>
  <TheMenuDietType />
  <TheIngredientExclusions />
  <TheMenuAllergens />
  <TheMenuNutrients />
</template>
